import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import compact from 'lodash/compact'
import first from 'lodash/first'
import groupBy from 'lodash/groupBy'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import last from 'lodash/last'
import moment from 'moment'
import sortBy from 'lodash/sortBy'
import sum from 'lodash/sum'
import { Button, Spinner } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import DownloadableLineItemsPdf, { TotalByPractice } from '../components/billing/downloadableLineItemsPdf'
import InvoiceItemsTable from '../components/invoices/table'
import Layout from '../components/layouts/Layout'
import LineItemsTable from '../components/invoices/lineItemsTable'
import MainBox from '../components/common/MainBox'
import OrganizationSelect from '../components/common/OrganizationSelect'
import PriceCalculator from '../utils/PriceCalculator'
import Statistic from '../components/invoices/statistic'
import TotalByPracticeTable from '../components/billing/totalByPracticeTable'
import { LineItem } from '../interfaces/LineItem'
import { MonthOption, consultationIsLate, getMonthsRange, parseDate, timeDiffHoursAndMinutes } from '../lib/timeHelpers'
import { Option, consultationDescription, formatDollarAmount, isAdmin, niceDate } from '../lib/helpers'
import { OrganizationsState, fetchOrganizationsAction, organizationsSelector } from '../hasura/slices/organizations'
import { Organizations_organizations } from '../hasura/graphQlQueries/types/Organizations'
import { StripeInvoiceItem } from '../interfaces/StripeInvoiceItem'
import { enterprisesSelector, EnterprisesState, fetchEnterpriseBillingAction } from '../hasura/slices/enterprises'
import { shouldDisplayTurnaroundTimes } from '../lib/userHelpers'
import { usersSelector, UsersState } from '../hasura/slices/users'

import {
  getAverageReturnTimesForStatTypes,
  getSoftwareServiceUnitAmountsForPriceGroup,
  statTypeDescription,
} from '../lib/pricingHelpers'

export default function Billing() {
  const dispatch = useDispatch()

  const { accessToken, user, role, defaultTurnaroundTime }: UsersState = useSelector(usersSelector)
  const { enterpriseBilling }: EnterprisesState = useSelector(enterprisesSelector)
  const { organizations }: OrganizationsState = useSelector(organizationsSelector)

  const [month, setMonth] = useState<MonthOption | null>(null)
  const [monthsRange, setMonthsRange] = useState<MonthOption[]>([])
  const [organization, setOrganization] = useState<Organizations_organizations | null>()

  /*
    Effects
  */

  useEffect(() => {
    fetchBilling()
  }, [month])

  useEffect(() => {
    if (!user || !accessToken) return

    const start = new Date(user.organization.created_at).toLocaleDateString('en-US')
    const end = new Date().toLocaleDateString('en-US')
    const monthsRange = getMonthsRange(start, end)
    setMonthsRange(monthsRange)
    if (monthsRange.length) setMonth(last(monthsRange)!)

    if (isAdmin(role) && !organizations.length) {
      dispatch(fetchOrganizationsAction(accessToken, user.organization.enterprise.id))
    }
  }, [user, accessToken])

  /* 
    Methods
  */

  const handleSelectOrganization = (option: Option | null) => setOrganization(organizations.find((o) => o.id === option?.value))

  const fetchBilling = () => {
    if (!user || !accessToken || !month) return

    const end = moment(month.value).add(1, 'month').startOf('month').format('MM/DD/YYYY')
    dispatch(fetchEnterpriseBillingAction(accessToken, user.organization.enterprise.id, month.value, end))
  }

  const displayTurnaroundTimes = shouldDisplayTurnaroundTimes(user, role)

  const lineItemColumns = compact([
    {
      Header: 'Date',
      accessor: (d: LineItem) => parseDate(d.date),
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Practice',
      accessor: 'practice',
    },
    {
      Header: 'Return time',
      accessor: (d: LineItem) => `${d.returnTime}`,
    },
    displayTurnaroundTimes && {
      Header: 'Turnaround time',
      accessor: (d: LineItem) => `${d.turnaroundTime}`,
    },
    {
      Header: 'Amount (USD)',
      accessor: 'amount',
    },
  ])

  const overflowConsults = (enterpriseBilling?.overflow_consults || []).filter(
    (c) => !organization || c.case.dicom_server?.organization_id === organization.id
  )

  const lineItems: LineItem[] = overflowConsults.map((c) => ({
    displayId: c.display_id,
    date: niceDate(c.completed_at),
    amount: `$${formatDollarAmount(c.overflow_price_amount)}`,
    description: consultationDescription(c).replace('Consultation for', 'Overflow consultation for'),
    turnaroundTime: timeDiffHoursAndMinutes(c.overflowed_at, c.completed_at),
    practice: c.case.dicom_server?.organization?.display_name,
    returnTime: statTypeDescription(defaultTurnaroundTime, c.stat_type),
    late: consultationIsLate(c),
  }))

  const overflowInvoiceItems = PriceCalculator.invoiceItemsFromConsultations(overflowConsults, true)

  const casesStored = (enterpriseBilling?.cases_stored || []).filter(
    (c) => !organization || c.dicom_server?.organization_id === organization.id
  ).length

  const aiAssessments = (enterpriseBilling?.ai_assessments || []).filter(
    (c) => !organization || c.dicom_server?.organization_id === organization.id
  ).length

  const supportedFullConsults = (enterpriseBilling?.supported_full_consults || []).filter(
    (c) => !organization || c.case.dicom_server?.organization_id === organization.id
  )

  const softwareServiceUnitAmounts = getSoftwareServiceUnitAmountsForPriceGroup(user?.organization.price_group)

  const invoiceItems: StripeInvoiceItem[] = enterpriseBilling
    ? [
        {
          description: 'Case stored',
          quantity: casesStored,
          unit_amount: softwareServiceUnitAmounts.caseStorage,
        },
        {
          description: 'AI assessment',
          quantity: aiAssessments,
          unit_amount: softwareServiceUnitAmounts.aiAssessment,
        },
        {
          description: 'Supported full consult',
          quantity: supportedFullConsults.length,
          unit_amount: softwareServiceUnitAmounts.supportedFullConsult,
        },
      ].concat(...overflowInvoiceItems)
    : []

  const invoiceTotal = formatDollarAmount(sum(invoiceItems.map((i) => i.quantity * i.unit_amount)) / 100).toLocaleString()

  const returnTimeMet = Math.round((100 * overflowConsults.filter((c) => !consultationIsLate(c)).length) / overflowConsults.length)

  const averageReturnTimesForStatTypes = getAverageReturnTimesForStatTypes(overflowConsults)

  const [isCreatingPdf, setIsCreatingPdf] = useState(false)

  const printDocument = async () => {
    setIsCreatingPdf(true)
    const input = document.getElementById('hiddenContent')
    if (!input) {
      setIsCreatingPdf(false)
      return
    }

    const sections = input.querySelectorAll('.page-section')
    let currentSection = 0
    const pdf = new jsPDF({ orientation: 'portrait', unit: 'mm', format: 'a4' })

    // @ts-ignore
    for (const section of sections) {
      await html2canvas(section, { scale: 1, useCORS: true }).then((canvas) => {
        const imgData = canvas.toDataURL('image/jpeg')
        const imgWidth = 210 // A4 width in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width
        const startY = currentSection === 1 ? 30 : 0
        pdf.addImage(imgData, 'JPG', 0, startY, imgWidth, imgHeight)
      })

      if (currentSection === sections.length - 1) {
        pdf.save('radimal-invoice-details.pdf')
        setIsCreatingPdf(false)
      } else if (currentSection > 0) {
        pdf.addPage()
      }
      currentSection++
    }
  }

  const endOfPeriod = month ? moment(month.value).add(1, 'month').startOf('month').format('MM/DD/YYYY') : undefined

  const consultsGroupedByPractice = groupBy(overflowConsults, (c) => c.case.dicom_server?.organization?.display_name)

  const totalByPractice: TotalByPractice[] = sortBy(
    Object.keys(consultsGroupedByPractice).map((practice) => ({
      practice,
      amount: sum(consultsGroupedByPractice[practice].map((o) => o.overflow_price_amount)) || 0,
      numberOfConsults: consultsGroupedByPractice[practice].length,
    })),
    'practice'
  )

  return (
    <Layout>
      <MainBox defaultPadding>
        <div className="d-flex align-items-start justify-content-between mb-2">
          <h4 className="bold">Billing</h4>

          <Button color="primary" disabled={isCreatingPdf} onClick={printDocument}>
            Download PDF
            {isCreatingPdf && <Spinner size="sm" className="ml-2" />}
          </Button>
        </div>

        <div className="mb-4 d-flex flex-column gap-10px max-width-350px">
          <OrganizationSelect
            organization={organization}
            organizations={organizations}
            handleSelectOrganization={handleSelectOrganization}
          />

          <Select
            placeholder="Select month..."
            classNamePrefix="react-select"
            options={monthsRange}
            value={month}
            // @ts-ignore
            onChange={(o: MonthOption) => setMonth(o)}
          />
        </div>

        <DownloadableLineItemsPdf
          enterpriseName={user?.organization.enterprise.short_name}
          lineItems={lineItems}
          period={`${first(month?.label.split(' '))} - ${endOfPeriod}`}
          totalByPractice={totalByPractice}
        />

        <div
          style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', gap: '20px' }}
          className="mb-4 w-100"
        >
          <Statistic title="Total Cases Stored" content={casesStored} />
          <Statistic title="Total AI Initial Assessments" content={aiAssessments} />
          {supportedFullConsults.length > 0 && (
            <Statistic
              title="Total Supported Full Consults"
              content={`${supportedFullConsults.length} (${supportedFullConsults.filter((c) => c.stat_type_id).length} STAT)`}
            />
          )}
          <Statistic
            title="Total Overflow Full Consults"
            content={`${overflowConsults.length} (${overflowConsults.filter((c) => c.stat_type).length} STAT)`}
          />
          {averageReturnTimesForStatTypes.map((s) => (
            // @ts-ignore
            <Statistic title={`${s.description} Average Turnaround`} content={s.turnaroundTime} key={s.description} />
          ))}
          {overflowConsults.length > 0 && (
            <Statistic title="Return Time Met" content={`${returnTimeMet}%`} highlight={returnTimeMet === 100} />
          )}
        </div>

        <InvoiceItemsTable data={invoiceItems} total={invoiceTotal} />
        {!organization && <TotalByPracticeTable data={totalByPractice} />}
        <LineItemsTable data={lineItems} columns={lineItemColumns} />
      </MainBox>
    </Layout>
  )
}
