import compact from 'lodash/compact'
import confetti from 'canvas-confetti'
import first from 'lodash/first'
import flatten from 'lodash/flatten'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import isInteger from 'lodash/isInteger'
import isNumber from 'lodash/isNumber'
import jwt_decode from 'jwt-decode'
import last from 'lodash/last'
import moment from 'moment'
import omit from 'lodash/omit'
import orderBy from 'lodash/orderBy'
import partition from 'lodash/partition'
import sortBy from 'lodash/sortBy'
import sum from 'lodash/sum'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import without from 'lodash/without'
import { IntercomProps } from 'react-use-intercom'

import CONFIG from '../config'
import { Case_cases, Case_cases_patient } from '../hasura/graphQlQueries/types/Case'
import { CompletedConsultations_consultations } from '../hasura/graphQlQueries/types/CompletedConsultations'
import { Conditions_conditions } from '../hasura/graphQlQueries/types/Conditions'
import { ConsultationsForAiComparison_consultations_case } from '../hasura/graphQlQueries/types/ConsultationsForAiComparison'
import { EnterpriseBilling_overflow_consults } from '../hasura/graphQlQueries/types/EnterpriseBilling'
import { Role } from '../interfaces/Role'
import { User_users } from '../hasura/graphQlQueries/types/User'
import { VetStatus } from '../hasura/slices/users'
import { VetsExpanded_users } from '../hasura/graphQlQueries/types/VetsExpanded'
import { additionalImagesCount } from './combineCompareHelpers'
import { predictions_normalized_insert_input } from '../../types/globalTypes'

import {
  Consultations_consultations,
  Consultations_consultations_case,
  Consultations_consultations_case_medical_images,
  Consultations_consultations_case_patient,
  Consultations_consultations_case_patient_cases,
} from '../hasura/graphQlQueries/types/Consultations'

export const getWindow = (paths: string[]): string | undefined => (typeof window === `undefined` ? undefined : get(window, paths))

export const isLocalhost = () => getWindow(['location', 'hostname'])?.includes('localhost')

export const searchQueryParams = (attr: string): string | undefined => {
  const search = getWindow(['location', 'search'])
  if (!search) return

  const queryParams = decodeURIComponent(search)
  const split = queryParams.split(`${attr}=`)

  if (split.length === 1) {
    // console.log(`${attr} not found in query params`)
    return
  }

  return split[1].split('&')[0]
}

export const parseRole = (accessToken: string): Role => {
  const roles = get(jwt_decode(accessToken), ['https://hasura.io/jwt/claims', 'x-hasura-allowed-roles']) || []

  if (roles.includes(Role.Admin)) {
    return Role.Admin
  } else if (roles.includes(Role.Vet)) {
    return Role.Vet
  } else if (roles.includes(Role.EnterpriseAdmin)) {
    return Role.EnterpriseAdmin
  } else {
    return Role.User
  }
}

// @ts-ignore
function hex2(c) {
  c = Math.round(c)
  if (c < 0) c = 0
  if (c > 255) c = 255

  var s = c.toString(16)
  if (s.length < 2) s = '0' + s

  return s
}

// @ts-ignore
function color(r, g, b) {
  return '#' + hex2(r) + hex2(g) + hex2(b)
}

export const shade = (col: string, light: number): string => {
  var r = parseInt(col.substr(1, 2), 16)
  var g = parseInt(col.substr(3, 2), 16)
  var b = parseInt(col.substr(5, 2), 16)

  if (light < 0) {
    r = (1 + light) * r
    g = (1 + light) * g
    b = (1 + light) * b
  } else {
    r = (1 - light) * r + light * 255
    g = (1 - light) * g + light * 255
    b = (1 - light) * b + light * 255
  }

  return color(r, g, b)
}

export const isChrome = (): boolean => {
  if (!hasWindow) return false

  // @ts-ignore
  var isChromium = window.chrome
  var winNav = window.navigator
  var vendorName = winNav.vendor
  // @ts-ignore
  var isOpera = typeof window.opr !== 'undefined'
  var isIEedge = winNav.userAgent.indexOf('Edge') > -1
  return (
    isChromium !== null && typeof isChromium !== 'undefined' && vendorName === 'Google Inc.' && isOpera === false && isIEedge === false
  )
}

const cookieKey = 'logged_into_radimal'
const cookieDomain = 'Domain=radimal.ai'

export const setIsLoggedInCookie = () => {
  if (!CONFIG.IS_PRODUCTION) return

  const expiresInMonths = 1
  const expirationDate = new Date()
  expirationDate.setMonth(expirationDate.getMonth() + expiresInMonths)
  const cookieString = `${cookieKey}=true; ${cookieDomain}; expires=${expirationDate.toUTCString()}`
  OLOG(`creating cookie... ${cookieString}`)
  document.cookie = cookieString
}

export const deleteCookie = () => {
  const cookieString = `${cookieKey}=; ${cookieDomain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
  OLOG(`deleting cookie... ${cookieString}`)
  document.cookie = cookieString
}

// @ts-ignore
export const setWithExpiry = (key, value, ttlSeconds) => {
  const now = new Date()
  const item = { value: value, expiry: now.getTime() + ttlSeconds * 1000 }
  OLOG(`setting cookie... ${item}`)
  localStorage.setItem(key, JSON.stringify(item))
}

// @ts-ignore
export const getWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key)
  if (!itemStr) return null

  const item = JSON.parse(itemStr)
  const now = new Date()
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key)
    return null
  }

  return item.value
}

export const sleep = (seconds: number) => new Promise((resolve) => setTimeout(resolve, seconds * 1000))

export const isSuperAdmin = (role?: Role): boolean => role === Role.Admin

export const isAdmin = (role?: Role): boolean => role === Role.Admin || role === Role.EnterpriseAdmin

export const isUser = (role?: Role): boolean => role === Role.User

export const isVet = (role?: Role): boolean => role === Role.Vet

export const isEnterpriseAdmin = (role?: Role): boolean => role === Role.EnterpriseAdmin

export const omitTimestamps = (obj: any) => omit(obj, ['created_at', 'updated_at'])

export const hasWindow = typeof window !== `undefined`

export const numberWithCommas = (x: number): string => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const OLOG = (data: any) => {
  if (CONFIG.IS_DEVELOPMENT || CONFIG.VERBOSE) console.log(data)
}

export const neuteredDescription = (neutered: boolean | null) => {
  if (neutered) return neutered ? 'neutered' : 'intact'
}

export const caseInsArrIncludes = (search: string, presentIn: string[]): boolean => presentIn.some((p) => caseInsEquals(p, search))

export const caseInsEquals = (search: string, presentIn: string): boolean => presentIn.toLowerCase() === search.toLowerCase()

export const medicalImageKeysForCase = (c: any): string[] => compact(c.medical_images.map((m: any) => keyForAwsS3Url(m.aws_s3_url)))

export const keyForAwsS3Url = (aws_s3_url?: string | null): string | undefined =>
  aws_s3_url ? last(aws_s3_url.split('/')) : undefined

export const emojiFor = (species: Species): string => {
  if (species === Species.Cat) {
    return '🐱 '
  } else if (species === Species.Dog) {
    return '🐶 '
  } else if (species === Species.Reptile) {
    return '🦎 '
  } else if (species === Species.Bird) {
    return '🦜 '
  } else if (species === Species.Rabbit) {
    return '🐇 '
  } else if (species === Species.Turtle) {
    return '🐢 '
  } else if (species === Species.Goat) {
    return '🐐 '
  } else if (species === Species.Rodent) {
    return '🐹 '
  } else if (species === Species.Frog) {
    return '🐸 '
  } else if (species === Species.Pig) {
    return '🐷 '
  } else {
    return ' '
  }
}

export enum Species {
  Cat = 'cat',
  Bird = 'bird',
  Dog = 'dog',
  Frog = 'frog',
  Goat = 'goat',
  Pig = 'pig',
  Rabbit = 'rabbit',
  Reptile = 'reptile',
  Rodent = 'rodent',
  Turtle = 'turtle',
}

export const ALL_SPECIES = [
  Species.Dog,
  Species.Cat,
  Species.Bird,
  Species.Frog,
  Species.Pig,
  Species.Rabbit,
  Species.Reptile,
  Species.Rodent,
  Species.Turtle,
]

export const speciesFor = (species: string | null = ''): Species => {
  if (!species) {
    return Species.Dog
  } else if (/(feline|fel|cat)/i.test(species)) {
    return Species.Cat
  } else if (/(canine|can|dog|k9)/i.test(species)) {
    return Species.Dog
  } else if (/(reptile|lizard)/i.test(species)) {
    return Species.Reptile
  } else if (/(bird|avian)/i.test(species)) {
    return Species.Bird
  } else if (/(lagomorph|lago|rabbit|lag)/i.test(species)) {
    return Species.Rabbit
  } else if (/(tortoise|turtle)/i.test(species)) {
    return Species.Turtle
  } else if (/(goat)/i.test(species)) {
    return Species.Goat
  } else if (/(pig)/i.test(species)) {
    return Species.Pig
  } else if (/(frog)/i.test(species)) {
    return Species.Frog
  } else if (/(guinea pig|hamster|rodent|small mammal|ferret)/i.test(species)) {
    return Species.Rodent
  } else {
    return Species.Dog
  }
}

export const ohifStudyUrl = (dicomServerStudyInstanceUid?: string | null, dicomSource?: string | null) => {
  if (!dicomServerStudyInstanceUid) return

  return dicomSource === 'dicom_cluster'
    ? `https://view.radimal.ai/viewer?StudyInstanceUIDs=${dicomServerStudyInstanceUid}`
    : `${CONFIG.VIEWER_URL}viewer/?StudyInstanceUIDs=${dicomServerStudyInstanceUid}`
}

export const nullOption = (label: string): Option => ({ value: null, label })

export interface Option {
  value: any | null
  label: string
  __isNew__?: boolean
}

export const formatDollarAmount = (amount: any): number => {
  try {
    return isInteger(amount) ? amount : amount.toFixed(2)
  } catch (error) {
    return 0
  }
}

export const validEmail = (email: string): boolean => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

// compatible in IE11 - https://stackoverflow.com/questions/27176983/dispatchevent-in-chrome-but-not-in-ie11
export const dispatchEvent = (msg: string) => {
  let event
  if (typeof Event === 'function') {
    event = new Event(msg)
  } else {
    event = document.createEvent('Event')
    event.initEvent(msg, true, true)
  }
  window.dispatchEvent(event)
}

export const imageKeyForS3Url = (awsS3Url: string): string => first(last(awsS3Url.split('/'))?.split('.jpg')) || ''

export const isOnFullConsultPlan = (user?: User_users): boolean =>
  first(user?.related_accounts?.stripe_profile?.subscriptions?.data)?.plan?.product === CONFIG.STRIPE_FULL_CONSULT_PLAN_ID

export const isOnAiFullConsultPlan = (user?: User_users): boolean =>
  first(user?.related_accounts?.stripe_profile?.subscriptions?.data)?.plan?.product === CONFIG.STRIPE_AI_FULL_CONSULT_PLAN_ID

export const userPlanName = (user?: User_users): string | undefined => {
  if (isOnFullConsultPlan(user)) {
    return 'Full Consult'
  } else if (isOnAiFullConsultPlan(user)) {
    return 'AI Full Consult'
  }
}

export const createIntercomParams = (user: User_users, role: Role): Partial<IntercomProps> => {
  const intercomParams: Partial<IntercomProps> = {
    userId: user.id,
    name: user.display_name,
    email: user.organization.primary_email,
    customAttributes: {
      'Company Name': user.organization.display_name,
      Enterprise: user.organization.enterprise.short_name,
      Role: role,
      'DICOM Servers': user.organization.dicom_servers.length,
      'Active Subscription': userPlanName(user) !== undefined,
      Subscription: userPlanName(user),
      'Number of Cases': sum(user.organization.dicom_servers.map((d) => d.cases_aggregate.aggregate?.count)),
    },
  }

  const auth0_profile = user.related_accounts?.auth0_profile
  if (auth0_profile?.picture) intercomParams.avatar = { type: 'avatar', imageUrl: auth0_profile?.picture }
  const hubSpotContactId = auth0_profile?.app_metadata?.hubSpotContactId
  const hubspotContactUrl = `https://app.hubspot.com/contacts/9331093/contact/${hubSpotContactId}`
  if (hubSpotContactId) intercomParams['customAttributes']!['Hubspot Contact Url'] = hubspotContactUrl
  const stripe_profile = user.related_accounts?.stripe_profile
  const stripeCustomerUrl = `https://dashboard.stripe.com/customers/${stripe_profile?.id}`
  if (stripe_profile?.id) intercomParams['customAttributes']!['Stripe Customer Url'] = stripeCustomerUrl
  const hasuraUserUrl = `https://api.radimal.ai/console/data/default/schema/public/tables/users/browse?filter=id%3B%24eq%3B${user.id}`
  intercomParams['customAttributes']!['Hasura User Url'] = hasuraUserUrl

  intercomParams['customAttributes']![
    'Hotjar Url'
  ] = `https://insights.hotjar.com/sites/2434520/playbacks/list?sort_by=visitor_uuid&filters=%7B%22AND%22:%5B%7B%22DAYS_AGO%22:%7B%22created%22:30%7D%7D,%7B%22EQUAL%22:%7B%22user_attributes.userId%22:%22${encodeURI(
    user.id
  )}%22%7D%7D%5D%7D`

  return intercomParams
}

export const pluralize = (text: string, n?: number) => `${n} ${text}${n == 1 ? '' : 's'}`

export const consultationDescription = (
  consultation: CompletedConsultations_consultations | EnterpriseBilling_overflow_consults,
  emoji = true
): string => {
  return `${emoji ? emojiFor(speciesFor(consultation.case.patient.species)) : ''}Consultation for ${
    consultation.case.patient.display_name
  }, ${consultationImagesDescription(consultation)}${consultation.stat_type?.hours ? `, ${consultation.stat_type?.hours}hr STAT` : ''}`
}

const consultationImagesDescription = (
  consultation: CompletedConsultations_consultations | CompletedConsultations_consultations | EnterpriseBilling_overflow_consults
) => {
  const linkedCases = consultation.consultation_linked_cases
  const caseImagesCount = consultation.case.medical_images_aggregate.aggregate?.count || 0
  if (!linkedCases.length) return `${pluralize('image', caseImagesCount)}`

  const totalImageCount = caseImagesCount + sum(linkedCases.map((c) => c.case.medical_images_aggregate.aggregate?.count || 0))
  return `${pluralize('image', totalImageCount)} total (${caseImagesCount} + ${linkedCases
    .map((c) => `${c.case.medical_images_aggregate.aggregate?.count} ${c.type === 1 ? 'combined' : 'compared'}`)
    .join(' + ')})`
}

export const adminConsultationDescription = (consultation: CompletedConsultations_consultations, emoji = true): string => {
  const originalEnterprise = consultation.case.dicom_server?.organization?.enterprise
  const wasCompletedByOverflowEnterprise =
    consultation.completed_by_enterprise_id && consultation.completed_by_enterprise_id !== originalEnterprise?.id

  return `${emoji ? emojiFor(speciesFor(consultation.case.patient.species)) : ''} ${consultation.case.patient.display_name} from ${
    consultation.case.dicom_server?.organization?.display_name
  }, ${consultationImagesDescription(consultation)}${
    consultation.stat_type?.hours ? `, ${consultation.stat_type?.hours}hr STAT` : ''
  }${wasCompletedByOverflowEnterprise ? `, Overflowed from ${originalEnterprise?.short_name}` : ''}`
}

export const commaSeparatedString = (arr: string[]): string | undefined => {
  if (!arr.length) return

  if (arr.length === 1) return arr[0]

  const firsts = arr.slice(0, arr.length - 1)
  const last = arr[arr.length - 1]
  return firsts.join(', ') + ' and ' + last
}

export const downloadCsv = (filename: string, rows: string[]) => {
  const content = 'data:text/csv;charset=utf-8,' + rows.join('\n')
  const encodedUri = encodeURI(content)
  const link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
}

export const niceDate = (timestamp: string) => new Date(timestamp).toLocaleDateString('en-US')

export const formatCents = (cents: number): string => (cents / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })

export const isPositive = (e: any) => isNumber(e) && e >= 0

export const trackHotjarEvent = (event: string) => {
  try {
    if (CONFIG.IS_DEVELOPMENT) {
      console.log('<HOTJAR_EVENT>', event)
    } else {
      // @ts-ignore
      window.hj('event', event)
    }
  } catch (error) {
    console.log('ERROR running window.hj event')
  }
}

export interface GroupedOption {
  label: string
  options: Option[]
}

export const filterConditions = (conditions: Conditions_conditions[], species: string | null | undefined): Conditions_conditions[] => {
  const filtered = conditions.filter((c) => species && c.species?.split(',').includes(species))
  return filtered.length ? filtered : conditions
}

export const conditionOptions = (
  conditions: Conditions_conditions[],
  exclude: predictions_normalized_insert_input[] = []
): GroupedOption[] => {
  const grouped = groupBy(
    conditions.filter((c) => !exclude.map((e) => e.condition_id).includes(c.id) && c.category),
    'category.display_name'
  )

  return Object.keys(grouped)
    .sort()
    .map((label) => ({
      label,
      options: sortBy(grouped[label], 'display_name').map((c) => ({
        label: c.display_name,
        value: c.id,
      })),
    }))
}

export const noHumanPrediction = (i: Consultations_consultations_case_medical_images) =>
  !i.predictions_normalizeds_aggregate.aggregate?.count

export const uniqueCategoriesFor = (conditions: Conditions_conditions[]) =>
  orderBy(compact(uniqBy(conditions, 'category.display_name').map((c) => c.category!)), 'order_index')

export const allIndexesOf = (substring: string, string: string) =>
  compact([...string.matchAll(new RegExp(substring, 'gi'))].map((a) => a.index))

type MinimalCase = {
  sending_vet_notes: string | null
  case: { medical_images_aggregate: { aggregate: { count: number } | null } }
}

export const requestedImagesCount = (c: MinimalCase) =>
  (c.case.medical_images_aggregate.aggregate?.count || 0) + additionalImagesCount(c.sending_vet_notes)

export const hotjarIdentify = (user: User_users, role: Role) => {
  try {
    // @ts-ignore
    window.hj('identify', user.id, { name: user.display_name, role })
  } catch (error) {
    console.log('ERROR running window.hj identify')
  }
}

export const orderConsultations = (consultations: Consultations_consultations[]) =>
  flatten(partition(sortBy(consultations, 'created_at'), 'stat_type'))

export const orderConsultationsForVet = (consultations: Consultations_consultations[], vetStatus: VetStatus) => {
  // filter STAT+ priority
  const filtered = consultations.filter(
    (c) =>
      !c.stat_type ||
      c.stat_type.hours === 4 ||
      Math.abs(moment(c.created_at).diff(moment(), 'minutes')) >= 2 ||
      vetStatus.my_stat_requests.concat(...vetStatus.my_locked_consultation_ids).includes(c.id)
  )
  // filter already claimed
  const unlocked = filtered.filter(
    (c) =>
      !without(
        vetStatus.locked_consultations.map((l) => l.consultation_id),
        ...vetStatus.my_locked_consultation_ids
      ).includes(c.id)
  )
  // claimed to the front, stat to the front
  return flatten(partition(orderConsultations(unlocked), (u) => vetStatus.my_locked_consultation_ids.includes(u.id)))
}

export const shootConfetti = () => {
  const canvas = document.createElement('canvas')
  canvas.className = 'fullscreen-confetti'
  document.body.appendChild(canvas)

  const myConfetti = confetti.create(canvas, {
    resize: true,
  })
  const duration = 1000
  const end = Date.now() + duration

  const colors = ['#FE01A1', '#B651E4', '#0077FF', '#0087F5', '#0088BF', '#008275']

  ;(function frame() {
    myConfetti({
      particleCount: 8,
      angle: 60,
      spread: 55,
      origin: { x: 0 },
      colors,
    })
    myConfetti({
      particleCount: 8,
      angle: 120,
      spread: 55,
      origin: { x: 1 },
      colors,
    })
    if (Date.now() < end) requestAnimationFrame(frame)
  })()
}

export const cleanDisplayName = (s: string) => s.replace(/\s*(?:[A-Z]{2,}|[A-Z]{2,}).*/, '').replace(/,\s*$/, '')

export const inCase = (
  preSignedUrls: string[],
  _case:
    | Consultations_consultations_case
    | Consultations_consultations_case_patient_cases
    | ConsultationsForAiComparison_consultations_case
    | Case_cases
): string[] => {
  const caseImageUrls = _case.medical_images.map((m) => m.aws_s3_url)
  return preSignedUrls.filter((s) => caseImageUrls.includes(first(s.split('?')) || ''))
}

export const vetImage = (vet?: VetsExpanded_users) =>
  vet?.related_accounts?.auth0_profile?.user_metadata?.image || vet?.related_accounts?.auth0_profile?.picture || ''

export const preloadImage = (src: string) => {
  const preload = new Image()
  preload.src = src
}

export const levenshteinDistance = (a: string, b: string): number => {
  const matrix: number[][] = []

  // Initialize matrix
  for (let i = 0; i <= b.length; i++) {
    matrix[i] = [i]
  }

  for (let j = 0; j <= a.length; j++) {
    matrix[0][j] = j
  }

  // Calculate Levenshtein distance
  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      const cost = a[j - 1] === b[i - 1] ? 0 : 1
      matrix[i][j] = Math.min(
        matrix[i - 1][j] + 1, // deletion
        matrix[i][j - 1] + 1, // insertion
        matrix[i - 1][j - 1] + cost // substitution
      )
    }
  }

  return matrix[b.length][a.length]
}

export const stringSimilarity = (a: string, b: string): number => {
  const distance = levenshteinDistance(a, b)
  const maxLength = Math.max(a.length, b.length)
  return 1 - distance / maxLength
}

export const vetNameWithoutQualifications = (s?: string) => (s || '').replace(/\,|DVM|DACVR|MS|VMD|EDI|-/g, '').trim()

export const vetFirstName = (s?: string) => (s || '').split(' ')[0]

export const postSentryError = (message: string, tags: { [key: string]: any } = {}) => {
  // @ts-ignore
  if (window.Sentry) {
    // @ts-ignore
    window.Sentry.captureMessage(message, { tags })
    if (CONFIG.IS_DEVELOPMENT) console.log(`<SENTRY> ${message} ${JSON.stringify(tags)}`)
  }
}

export const isExotic = (species?: string | null) => ![Species.Dog, Species.Cat].includes(speciesFor(species))

export const patientInfo = (patient: Consultations_consultations_case_patient | Case_cases_patient) =>
  compact([
    patient.breed,
    patient.gender,
    neuteredDescription(patient.neutered),
    patient.age,
    patient.birthdate ? `born ${patient.birthdate}` : undefined,
    patient.weight,
  ]).join(' · ')

export const emailToName = (email: string) => {
  // Extract the substring before the '@' symbol
  const name = email.slice(0, email.indexOf('@'))
  // Replace any dots or underscores with spaces
  const formattedName = name.replace(/[._]/g, ' ')
  // Capitalize the first letter of each word in the name
  const capitalizedName = formattedName.replace(/\b\w/g, (char) => char.toUpperCase())
  const BAD_STRINGS = ['vet', 'animal', 'pet', 'info', 'staff', 'reception', 'client', 'admin']
  if (BAD_STRINGS.some((word) => capitalizedName.toLowerCase().includes(word))) return

  return capitalizedName
}

export const findDuplicates = (arr: any[]) => uniq(arr.filter((a) => arr.filter((a2) => a2 === a).length > 1))

export const parseIdFrom = (accessToken: string): string | undefined => {
  try {
    return get(jwt_decode(accessToken), 'sub')
  } catch {}
}

export const isDemoAccount = (accessToken: string) => parseIdFrom(accessToken) === CONFIG.DEMO_ORGANIZATION_USER_ID

export const isDemoOrg = (user?: User_users) => user?.id === CONFIG.DEMO_ORGANIZATION_USER_ID

export const enterpriseIdsForUser = (user: User_users) =>
  user.user_roles.length ? user.user_roles.map((r) => r.enterprise.id) : [user.organization.enterprise.id]

export const removeSubstrings = (inputString: string, substringsToRemove: string[]) => {
  const regex = new RegExp(substringsToRemove.join('|'), 'g')
  return inputString.replace(regex, '')
}

export const phoneRegExp = /^(\+?\d{1,4}[\s-])?(\(?\d{3}\)?[\s-]?)?[\d\s-]{7,10}$/

export const blurCurrentInput = () => {
  if (document.activeElement && document.activeElement.tagName === 'INPUT') {
    // @ts-ignore
    document.activeElement.blur()
  }
}
