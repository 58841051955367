import React, { useState } from 'react'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import { Button, Input } from 'reactstrap'

// @ts-ignore
import deleteIcon from '../../lib/images/subtracting-button.svg'

interface Props {
  amount?: number
  delete?: () => void
  displayEnable?: boolean
  enable?: () => void
  page: 'price group' | 'pay rates'
  update: (amount: number) => void
}

export default function SimpleStatInput(props: Props) {
  const [newAmount, setNewAmount] = useState<string | undefined>()

  const isPriceGroupPage = props.page === 'price group'

  if (!isNumber(props.amount) && props.page === 'pay rates') {
    return null
  }

  if (props.displayEnable) {
    return (
      <div>
        <div>
          <p className="mb-0 semibold text-s">Enable Simple STAT</p>
          <p className="mb-1 text--gray7 text-xs">1HR return time for radiograph cases w/ lt. 3 images</p>
        </div>

        <Button className="width-fit-content" onClick={props.enable} size="sm" color="success">
          Enable
        </Button>
      </div>
    )
  }

  return (
    <div>
      <div>
        <div className="d-flex align-items-center">
          <p className="mb-0 semibold text-s">Set a Simple STAT price</p>
          {isPriceGroupPage && <img onClick={props.delete} src={deleteIcon} alt="delete" className="ml-2 icon-xxxs pointer" />}
        </div>
        <p className="mb-1 text--gray7 text-xs">1HR return time for radiograph cases w/ lt. 3 images</p>
      </div>

      <div className="flex-center width-fit-content">
        $
        <Input
          className="ml-1 width-80px"
          value={isString(newAmount) ? newAmount : props.amount || ''}
          onChange={(e: any) => setNewAmount(e.target.value)}
        />
        <Button
          className="ml-3 width-fit-content"
          onClick={() => {
            const amount = parseInt(newAmount || '', 10)
            if (!amount) return

            setNewAmount(undefined)
            props.update(amount)
          }}
          disabled={!newAmount}
          size="sm"
          color="secondary"
        >
          Save
        </Button>
      </div>
    </div>
  )
}
