import React from 'react'
import last from 'lodash/last'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import PatientSearch, { PatientSearchParentType } from './PatientSearch'
import { Cases_cases } from '../../hasura/graphQlQueries/types/Cases'
import { Patients_patients } from '../../hasura/graphQlQueries/types/Patients'
import { UsersState, usersSelector } from '../../hasura/slices/users'
import { fetchPatientsAction, updateCasePatientIdAction } from '../../hasura/slices/patients'
import { insertOrganizationEventForUserAction } from '../../hasura/slices/organizations'
import { OrganizationEvent } from '../../lib/organizationEventTypes'

interface Props {
  case: Cases_cases
  handleMoveImages: () => void
  setSwitchPatientCase: (switchPatientModalOpen?: Cases_cases) => void
}

export default function SwitchPatientModal(props: Props) {
  const dispatch = useDispatch()

  const { accessToken, user }: UsersState = useSelector(usersSelector)

  const [selectedPatient, setSelectedPatient] = React.useState<Patients_patients | undefined>(undefined)

  const { patient, medical_images } = props.case
  const organization_id = props.case.dicom_server?.organization_id

  const handleMoveImages = async () => {
    if (!accessToken || !selectedPatient || !user) return

    await dispatch(updateCasePatientIdAction(accessToken, props.case.id, selectedPatient.id))
    dispatch(insertOrganizationEventForUserAction(accessToken, OrganizationEvent.MovedPatientImages, user.organization.id))
    dispatch(fetchPatientsAction(accessToken, user.organization.id, ''))
    props.handleMoveImages()
  }

  return (
    <Modal
      fade={false}
      centered
      autoFocus={false}
      isOpen
      className="position-relative"
      toggle={() => props.setSwitchPatientCase(undefined)}
    >
      <ModalHeader>Assign case to another patient ID</ModalHeader>

      <ModalBody className="p-4 min-height-200px ">
        <div className="d-flex flex-column">
          <p className="mb-2">
            Assign <span className="semibold">{medical_images.length} image</span> case from{' '}
            <span className="semibold">{patient.display_name}</span> (ID {last(patient.dicom_server_patient_id.split('-'))}) to another
            patient ID:
          </p>

          {organization_id && (
            <PatientSearch
              parent={PatientSearchParentType.SwitchPatientModal}
              filterPatientId={selectedPatient?.id}
              handleSelectedPatient={(patient?: Patients_patients) => setSelectedPatient(patient)}
              organizationId={organization_id}
            />
          )}
        </div>

        <div className="d-flex justify-content-center mt-4">
          <Button onClick={handleMoveImages} disabled={!selectedPatient} color="primary">
            Assign case
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}
