import React, { useState } from 'react'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import orderBy from 'lodash/orderBy'
import { Button, Input } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import AddonPricingModal from './addonPricingModal'
import CONFIG from '../../config'
import PriceGroupMenu from './menu'
import SimpleStatInput from '../common/SimpleStatInput'
import SoftwareServicesTable from '../pricing/softwareServicesTable'
import { CatscanRegions } from '../request-consult/config'
import { ConsultationType, Modality, getConsultationTypeData } from '../../lib/modalityHelpers'
import { NotificationId, setNotificationAction } from '../../hasura/slices/notifications'
import { PriceGroups_price_groups, PriceGroups_price_groups_prices } from '../../hasura/graphQlQueries/types/PriceGroups'
import { Table, Header, HeaderCell, Cell, Row } from '../common/Table'
import { blurCurrentInput, pluralize } from '../../lib/helpers'
import { softwareChargeTypeForId } from '../../lib/softwareChargeTypes'
import { usersSelector, UsersState } from '../../hasura/slices/users'

import {
  WEEKEND_STAT_ADDON_ID,
  getExtraImagePrices,
  thresholdsForAddOnTypes,
  getStatTypeDescription,
  getStatTypeDescriptions,
  getStatPrice,
} from '../../lib/pricingHelpers'

import {
  PricingState,
  addModalityToPriceGroupAction,
  deletePriceGroupAction,
  deletePricesAction,
  insertPricesAction,
  pricingSelector,
  updateDefaultPriceGroupAction,
  updatePriceAmountAction,
} from '../../hasura/slices/pricing'

// @ts-ignore
import starPinkIcon from '../../lib/images/star-pink.svg'
// @ts-ignore
import starGrayStrokeIcon from '../../lib/images/star-gray-stroke.svg'

const getPricesForConsultationType = (group: PriceGroups_price_groups, consultationType: ConsultationType) =>
  group.prices.filter((p) => p.consultation_type_id === getConsultationTypeData(consultationType)?.consultationTypeId)

const getBasePrice = (prices: PriceGroups_price_groups_prices[]) => prices.find((p) => !p.addon_type && !p.addon && !p.stat_type)

const ultrasoundAddonDescription = (addOnPrice: PriceGroups_price_groups_prices) => {
  const data = addOnPrice?.addon?.additional_data
  return `${data['image_threshold']}+ images / ${data['video_threshold']}+ cineloops`
}

interface Props {
  group: PriceGroups_price_groups
}

interface NewAmount {
  id: number
  value: string
  parsedValue: number
  valid: boolean
  isBase: boolean
  basePriceAmount: number
}

export default function PriceGroup(props: Props) {
  const dispatch = useDispatch()

  const [addonPricingModalConsultationType, setAddonPricingModalConsultationType] = useState<ConsultationType | undefined>()
  const [newAmount, setNewAmount] = useState<NewAmount | undefined>()
  const [newWeekendStatPrice, setNewWeekendStatPrice] = useState<string | undefined>()

  const { group } = props

  const { accessToken, defaultTurnaroundTime, user }: UsersState = useSelector(usersSelector)
  const { statTypes }: PricingState = useSelector(pricingSelector)

  const simpleStatPrice = group.prices.find((p) => p.stat_type?.display_name === 'simple')
  const baseRadiographPriceAmount = getBasePrice(getPricesForConsultationType(group, ConsultationType.Xray))?.amount

  const isDefaultPriceGroup = user?.organization.enterprise.default_price_group_id === group.id

  const xrayPrices = getPricesForConsultationType(group, ConsultationType.Xray)
  const ultrasoundPrices = getPricesForConsultationType(group, ConsultationType.Ultrasound)
  const catscanPrices = getPricesForConsultationType(group, ConsultationType.Catscan)
  const internalMedicinePrices = getPricesForConsultationType(group, ConsultationType.InternalMedicine)

  const extraImageXrayPrices = getExtraImagePrices(xrayPrices, false, Modality.Xray)
  const extraImageUltrasoundPrices = getExtraImagePrices(
    ultrasoundPrices,
    false,
    Modality.Ultrasound
  ) as PriceGroups_price_groups_prices[]

  const extraImageXrayThresholdDescriptions = thresholdsForAddOnTypes([
    null,
    ...extraImageXrayPrices.map((p) => p.addon?.additional_data['threshold']),
  ])

  const catscanAddOnTypes: (number | null)[] = [null].concat(
    // @ts-ignore
    ...orderBy(
      catscanPrices.filter((p) => p.addon?.display_name === 'Extra Regions'),
      [(p) => p.addon?.additional_data['threshold'] || 0],
      ['asc']
    ).map((p) => p.addon?.id)
  )

  const statTypesForGroup = statTypes.filter((s) => group.prices.map((p) => p.stat_type?.id).includes(s.id))

  let statTypeDescriptions = getStatTypeDescriptions(group.prices, defaultTurnaroundTime)
  if (statTypeDescriptions.length === 0) statTypeDescriptions = [getStatTypeDescription(defaultTurnaroundTime, null)]

  const softwarePrices = group.prices
    .filter((p) => p.software_charge_type_id)
    .map(({ amount, software_charge_type_id }) => ({ amount, description: softwareChargeTypeForId(software_charge_type_id!) }))

  const missingConsultationTypes = [ConsultationType.Xray, ConsultationType.Catscan, ConsultationType.Ultrasound].filter(
    (c) => getPricesForConsultationType(group, c).length === 0
  )

  const weekendStatPrice = group.prices.find((p) => p.addon?.display_name === 'Weekend STAT')

  /*
    Methods
  */

  const saveNewAmount = async () => {
    if (newAmount?.parsedValue && newAmount?.valid) {
      const amount = newAmount.isBase ? newAmount.parsedValue : newAmount.parsedValue - newAmount.basePriceAmount
      await dispatch(updatePriceAmountAction(accessToken, user!, newAmount.id, amount))
      setNewAmount(undefined)
    }
    blurCurrentInput()
    setNewAmount(undefined)
  }

  const addModality = (modality: Modality) =>
    dispatch(addModalityToPriceGroupAction(accessToken, user!, group.id, modality, statTypesForGroup))

  const deletePriceGroup = async () => {
    if ((group.organizations_aggregate.aggregate?.count || 0) > 0) {
      alert('You cannot delete a price group that is associated with multiple practices')
      return
    }

    await dispatch(deletePriceGroupAction(accessToken, user!, group.id))
  }

  const setDefaultPriceGroup = () => dispatch(updateDefaultPriceGroupAction(accessToken, user!, group.id))

  const editablePriceCellParamsFor = (
    consultationType: ConsultationType,
    statTypeDescription: string | null,
    addOnPriceAmount?: any
  ) => {
    const prices = getPricesForConsultationType(group, consultationType)
    const basePrice = getBasePrice(prices)
    const basePriceAmount = basePrice?.amount || 0
    const statPrice = getStatPrice(statTypeDescription, defaultTurnaroundTime, prices)
    const statPriceAmount = statPrice?.amount || 0
    const isBase = !statPrice && !addOnPriceAmount
    const amount = isBase ? basePriceAmount : basePriceAmount + (addOnPriceAmount || 0) + statPriceAmount
    const priceId = isBase ? basePrice?.id : statPrice?.id
    const isEditing = newAmount?.id === priceId && !addOnPriceAmount
    return {
      consultationType,
      amount,
      basePriceAmount,
      statPriceAmount,
      addOnPriceAmount,
      priceId: priceId!,
      isBase,
      isEditing,
      statTypeDescription,
    }
  }

  const saveNewWeekendStatPrice = async () => {
    const parsedAmount = parseFloat(newWeekendStatPrice || '')
    if (isNaN(parsedAmount) || parsedAmount <= 0) {
      dispatch(setNotificationAction(NotificationId.InvalidAmount))
      return
    }

    if (weekendStatPrice) {
      dispatch(updatePriceAmountAction(accessToken, user!, weekendStatPrice.id, parsedAmount))
    } else {
      const price = { price_group_id: props.group.id, amount: parsedAmount, addon_id: WEEKEND_STAT_ADDON_ID }
      await dispatch(insertPricesAction(accessToken, user!, [price]))
      dispatch(setNotificationAction(NotificationId.PriceSaved))
    }
  }

  const editablePriceCell = (params: {
    consultationType: ConsultationType
    priceId: number
    amount: number
    isBase: boolean
    basePriceAmount: number
    addOnPriceAmount: number
    statPriceAmount: number
    isEditing: boolean
    statTypeDescription: string | null
  }) => {
    let amountMsg = ``
    if (params.statPriceAmount) {
      amountMsg += `+ $${params.statPriceAmount} (STAT)`
    }
    if (params.addOnPriceAmount) {
      amountMsg += ` + $${params.addOnPriceAmount} (${params.consultationType === ConsultationType.Catscan ? 'regions' : 'images'})`
    }
    return (
      <Cell key={`${params.priceId}-${params.statTypeDescription}`}>
        <div className="d-flex align-items-center position-relative py-1">
          $
          <Input
            className="ml-1 width-80px"
            id={`price-${params.priceId}`}
            onBlur={saveNewAmount}
            disabled={isNumber(params.addOnPriceAmount)}
            onKeyDown={(e: any) => e.key === 'Enter' && saveNewAmount()}
            value={params.isEditing ? newAmount?.value : params.amount}
            onChange={(e: any) =>
              setNewAmount({
                id: params.priceId!,
                value: e.target.value,
                parsedValue: parseFloat(e.target.value),
                valid: !isNaN(parseFloat(e.target.value)),
                isBase: params.isBase,
                basePriceAmount: params.basePriceAmount,
              })
            }
            onFocus={() =>
              setNewAmount({
                id: params.priceId!,
                value: '',
                parsedValue: 0,
                valid: true,
                isBase: params.isBase,
                basePriceAmount: params.basePriceAmount,
              })
            }
          />
          {!params.isEditing && !params.isBase && (
            <p
              style={{ top: '43px', left: '14px', lineHeight: '14px' }}
              className="text--gray4 text-xxs position-absolute single-line"
            >
              {amountMsg}
            </p>
          )}
          {params.isEditing && !newAmount?.valid && (
            <p style={{ top: '43px', left: '14px', lineHeight: '14px' }} className="text-danger text-xxs bold position-absolute">
              Invalid amount
            </p>
          )}
        </div>
      </Cell>
    )
  }

  return (
    <div key={group.id} className={`my-4 p-4 border rounded ${isDefaultPriceGroup ? 'border-primary' : ''}`}>
      {addonPricingModalConsultationType && (
        <AddonPricingModal
          close={() => setAddonPricingModalConsultationType(undefined)}
          consultationType={addonPricingModalConsultationType}
          priceGroup={group}
        />
      )}

      <div className="d-flex justify-content-between">
        <p className="text-m text--gray7 m-0">
          <span className="bold">PRICE GROUP {group.id}</span> |{' '}
          {pluralize('practice', group.organizations_aggregate.aggregate?.count || 0)}
        </p>

        <PriceGroupMenu deletePriceGroup={deletePriceGroup} />
      </div>

      <Table>
        <thead>
          <tr>
            <HeaderCell>
              <Header>Type</Header>
            </HeaderCell>

            <HeaderCell />

            {statTypeDescriptions.map((type, idx) => (
              <HeaderCell key={idx}>
                <Header>{type}</Header>
              </HeaderCell>
            ))}
          </tr>
        </thead>

        <tbody>
          {[null, ...extraImageXrayPrices].map((addOnPrice, idx) => {
            const addOnPriceAmount = addOnPrice?.amount
            const addOnDescription = extraImageXrayThresholdDescriptions[idx]

            return (
              <Row key={idx}>
                <Cell>{idx === 0 ? 'Radiographs' : ''}</Cell>
                <Cell>{addOnDescription}</Cell>

                {statTypeDescriptions.map((statType) => {
                  return editablePriceCell(editablePriceCellParamsFor(ConsultationType.Xray, statType, addOnPriceAmount))
                })}
              </Row>
            )
          })}

          {ultrasoundPrices.length > 0 &&
            [null, ...extraImageUltrasoundPrices].map((addOnPrice, idx) => {
              const addOnPriceAmount = addOnPrice?.amount

              return (
                <Row key={idx}>
                  <Cell>{idx === 0 ? 'Ultrasound' : ''}</Cell>
                  <Cell>{addOnPrice?.amount ? ultrasoundAddonDescription(addOnPrice) : ''}</Cell>

                  {statTypeDescriptions.map((statType) => {
                    return editablePriceCell(editablePriceCellParamsFor(ConsultationType.Ultrasound, statType, addOnPriceAmount))
                  })}
                </Row>
              )
            })}

          {catscanPrices.length > 0 &&
            catscanAddOnTypes.map((addOnType, idx) => {
              const addOnPrice = catscanPrices.find((p) => p.addon?.id === addOnType)
              const addOnPriceAmount = addOnPrice?.amount
              const addOnThreshold = addOnPrice?.addon?.additional_data['threshold'] || 0
              const addOnTypeDescription =
                addOnThreshold == 2
                  ? CatscanRegions.WholeBody
                  : addOnThreshold == 1
                  ? CatscanRegions.TwoRegions
                  : CatscanRegions.OneRegion

              return (
                <Row key={idx}>
                  <Cell>{idx === 0 ? 'CT' : ''}</Cell>
                  <Cell>{addOnTypeDescription}</Cell>

                  {statTypeDescriptions.map((statType) => {
                    return editablePriceCell(editablePriceCellParamsFor(ConsultationType.Catscan, statType, addOnPriceAmount))
                  })}
                </Row>
              )
            })}

          {internalMedicinePrices.length > 0 && (
            <Row>
              <Cell>Internal Medicine</Cell>
              <Cell />

              {statTypeDescriptions.map((statType) => {
                return editablePriceCell(editablePriceCellParamsFor(ConsultationType.InternalMedicine, statType, 0))
              })}
            </Row>
          )}
        </tbody>
      </Table>

      {missingConsultationTypes.length > 0 && (
        <div className="mt-4">
          <p className="bold text--gray7 text-m mb-1">Add new modalities</p>

          <div className="d-flex gap-10px">
            {missingConsultationTypes.map((consultationType) => (
              <Button
                className="width-150px"
                key={consultationType}
                // @ts-ignore
                onClick={() => addModality(getConsultationTypeData(consultationType)?.modality)}
                size="sm"
              >
                Add {consultationType}
              </Button>
            ))}
          </div>
        </div>
      )}

      <div className="mt-4">
        <p className="bold text--gray7 text-m mb-1">Addon pricing</p>

        <div className="d-flex mt-2 gap-80px">
          <div>
            <div>
              <p className="mb-1 semibold text-s">Update extra image pricing for radiographs</p>
              <Button
                className="d-block width-fit-content"
                onClick={() => setAddonPricingModalConsultationType(ConsultationType.Xray)}
                size="sm"
              >
                Update
              </Button>
            </div>

            {ultrasoundPrices.length > 0 && (
              <div className="mt-2">
                <p className="mb-1 semibold text-s">Update extra region pricing for ultrasound</p>
                <Button
                  className="d-block width-fit-content"
                  onClick={() => setAddonPricingModalConsultationType(ConsultationType.Ultrasound)}
                  size="sm"
                >
                  Update
                </Button>
              </div>
            )}

            {catscanPrices.length > 0 && (
              <div className="mt-2">
                <p className="mb-1 semibold text-s">Update extra region pricing for CT</p>
                <Button
                  className="d-block width-fit-content"
                  onClick={() => setAddonPricingModalConsultationType(ConsultationType.Catscan)}
                  size="sm"
                >
                  Update
                </Button>
              </div>
            )}
          </div>

          <div className="gap-10px d-flex flex-column">
            <div>
              <p className="mb-1 semibold text-s">Set a Weekend STAT price</p>
              <div className="flex-center width-fit-content">
                $
                <Input
                  className="ml-1 width-80px"
                  value={isString(newWeekendStatPrice) ? newWeekendStatPrice : weekendStatPrice?.amount || ''}
                  onChange={(e: any) => setNewWeekendStatPrice(e.target.value)}
                />
                <Button
                  className="ml-3 width-fit-content"
                  onClick={saveNewWeekendStatPrice}
                  disabled={!newWeekendStatPrice}
                  size="sm"
                  color="secondary"
                >
                  Save
                </Button>
              </div>
            </div>

            <SimpleStatInput
              amount={baseRadiographPriceAmount + simpleStatPrice?.amount}
              displayEnable={!simpleStatPrice}
              page="price group"
              delete={() => dispatch(deletePricesAction(accessToken, user!, [simpleStatPrice!.id]))}
              enable={() => {
                const consultation_type_id = getConsultationTypeData(ConsultationType.Xray)?.consultationTypeId
                const price = { price_group_id: group.id, amount: 0, stat_type_id: CONFIG.SIMPLE_STAT_ID, consultation_type_id }
                dispatch(insertPricesAction(accessToken, user!, [price]))
              }}
              update={(amount: number) =>
                dispatch(updatePriceAmountAction(accessToken, user!, simpleStatPrice!.id, amount - baseRadiographPriceAmount))
              }
            />
          </div>
        </div>
      </div>

      <SoftwareServicesTable softwarePrices={softwarePrices} />

      <div className="d-flex align-items-end justify-content-end gap-10px">
        {isDefaultPriceGroup && (
          <p className="text-xs m-0 text--gray5">
            This is your default price group. New practices will automatically be assigned to this group.
          </p>
        )}
        <img onClick={setDefaultPriceGroup} className="icon-s pointer" src={isDefaultPriceGroup ? starPinkIcon : starGrayStrokeIcon} />
      </div>
    </div>
  )
}
