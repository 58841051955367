import React, { useState, useEffect } from 'react'
import first from 'lodash/first'
import moment from 'moment-timezone'
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, Progress, UncontrolledDropdown } from 'reactstrap'
import { navigate } from 'gatsby-link'
import { useDispatch, useSelector } from 'react-redux'
import { useIntercom } from 'react-use-intercom'

import CONFIG from '../../config'
import StatSelect from '../stat/StatSelect'
import { Cases_cases, Cases_cases_consultations } from '../../hasura/graphQlQueries/types/Cases'
import { Cell } from '../../components/common/Table'
import { NotificationId, setNotificationAction } from '../../hasura/slices/notifications'
import { QueryName } from '../../hasura/queryNames'
import { casesSelector, CasesState, fetchCaseAction } from '../../hasura/slices/cases'
import { displayEnableBillingModalAction, usersSelector, UsersState } from '../../hasura/slices/users'
import { getStatPriceAmount, statTypeDescription } from '../../lib/pricingHelpers'
import { isAdmin, isExotic, speciesFor, ohifStudyUrl, isUser } from '../../lib/helpers'

import {
  availableConsultationTypeAfterFirstRequest,
  ConsultationType,
  getConsultationTypeForId,
  modalityFor,
} from '../../lib/modalityHelpers'

import {
  consultationsSelector,
  ConsultationsState,
  deleteConsultationAction,
  Patient,
  updateConsultationOverflowAction,
  upgradeStatAction,
} from '../../hasura/slices/consultations'

// @ts-ignore
import closeIcon from '../../lib/images/close.png'

// @ts-ignore
import dots from '../../lib/images/dots.svg'

interface Props {
  _case: Cases_cases
  fetchCases: () => void
  shouldPopBillingModal: boolean
  viewConsultationReport: (
    consultation: Cases_cases_consultations,
    key: string,
    patient: Patient,
    privateNotes?: string,
    viewerLink?: string
  ) => void
}

export default (props: Props) => {
  const dispatch = useDispatch()
  const { trackEvent } = useIntercom()

  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false)

  const [statType, setStatType] = useState<any | undefined>(undefined)
  const { accessToken, vetStatus, role, defaultTurnaroundTime, user, isRadimalEnterprise }: UsersState = useSelector(usersSelector)
  const { radimalCase }: CasesState = useSelector(casesSelector)
  const { isQuerying }: ConsultationsState = useSelector(consultationsSelector)

  const { _case } = props
  const consultation = first(_case.consultations)
  const requested = consultation?.status === 'requested'
  const complete = consultation?.status === 'completed'
  const isStat = consultation?.stat_type_id !== null
  const isStatUpgradeable = requested && !isStat && vetStatus?.stat_available
  const isEditable = requested && (isUser(role) || isAdmin(role)) && !consultation.receiving_vet_notes?.length
  const hasMultipleCompletedConsultations = _case.consultations.filter((c) => c.status === 'completed').length > 1
  const nextRequestConsultationType = isRadimalEnterprise ? availableConsultationTypeAfterFirstRequest(_case) : undefined

  useEffect(() => {
    if (!upgradeModalOpen) return

    dispatch(fetchCaseAction(accessToken, _case.id, false))
  }, [upgradeModalOpen])

  const handleUpgradeSubmit = async () => {
    await dispatch(
      upgradeStatAction(
        accessToken,
        consultation!.id,
        getStatPriceAmount(statType.value, radimalCase!, modalityFor(radimalCase)),
        statType.value
      )
    )
    props.fetchCases()
    trackEvent('upgraded-stat', { patient: _case.patient.display_name })
    dispatch(setNotificationAction(NotificationId.UpgradedStat))
    setUpgradeModalOpen(false)
  }

  const handleDelete = async () => {
    await dispatch(deleteConsultationAction(accessToken, consultation!.id))
    props.fetchCases()
  }

  const handleUpdateOverflow = async () => {
    if (!consultation) return

    await dispatch(updateConsultationOverflowAction(accessToken, consultation.id, !consultation.overflow))
    props.fetchCases()
  }

  const createdAtTime = moment.tz(consultation?.upgraded_at || consultation?.created_at, moment.tz.guess())
  const defaultHours = isExotic(_case.patient.species) ? 72 : defaultTurnaroundTime
  const minutes = (consultation?.stat_type?.hours || defaultHours) * 60
  const completeByTime = createdAtTime.clone().add(consultation?.stat_type?.hours || defaultHours, 'hours')
  const progress = ((minutes - completeByTime.diff(moment(), 'minutes')) / 60) * 100
  const isImmediateStat = consultation?.stat_type?.hours === 0

  const hasViewedReportKey = `viewed_consultation_report_${consultation?.id}`
  const hasViewedReport = localStorage.getItem(hasViewedReportKey) === 'true'
  const wasCompletedToday = consultation?.completed_at && moment(consultation?.completed_at).isSame(moment(), 'day')
  const displayNewReportBorder = !hasViewedReport && complete && wasCompletedToday

  return (
    <Cell colSpan={2} className="text-center">
      <div className="d-flex justify-content-between align-items-center w-100 px-2">
        <div className="flex-even" />

        <div>
          {requested && isAdmin(role) && (
            <Button
              className="button-w-m"
              color="danger"
              onClick={() => {
                if (window.confirm(`Are you sure you want to cancel ${_case.patient.display_name}'s consultation request?`)) {
                  handleDelete()
                }
              }}
            >
              Cancel
            </Button>
          )}

          {complete && (
            <Cell colSpan={2} className="text-center">
              {consultation.s3_url && (
                <div className={displayNewReportBorder ? `border border--success p-1 rounded border-width-2px` : ''}>
                  <Button
                    className="min-width-100px"
                    color="primary"
                    onClick={() => {
                      trackEvent('viewed-consultation-report', { patient: _case.patient.display_name })
                      localStorage.setItem(`viewed_consultation_report_${consultation.id}`, 'true')
                      props.viewConsultationReport(
                        consultation,
                        consultation!.s3_url!,
                        _case.patient,
                        consultation.receiving_vet_private_notes || undefined,
                        ohifStudyUrl(_case.dicom_server_study_instance_uid, _case.dicom_source)
                      )
                    }}
                  >
                    View
                  </Button>
                </div>
              )}

              <p className="text-xxs m-0 mt-1">Complete</p>
            </Cell>
          )}

          {(!requested || !isAdmin(role)) && !complete && (
            <Button
              className="button-w-m single-line min-width-100px"
              color="primary"
              disabled={
                (requested && !isStatUpgradeable) || (isExotic(_case.patient.species || '') && !vetStatus?.available_for_exotics)
              }
              onClick={() => {
                if (isStatUpgradeable) {
                  trackEvent('clicked-upgrade-stat', { patient: _case.patient.display_name })
                  setUpgradeModalOpen(true)
                } else if (props.shouldPopBillingModal && !CONFIG.IS_DEVELOPMENT) {
                  dispatch(displayEnableBillingModalAction())
                } else {
                  navigate(`/request-consult?i=${_case.id}`)
                }
              }}
            >
              {!requested ? 'Request' : isStatUpgradeable ? 'STAT Upgrade' : 'Requested'}
            </Button>
          )}

          {requested && (
            <div className="width-fit-content mx-auto mt-2">
              {consultation.stat_type && !isImmediateStat && !isAdmin(role) && (
                <div className={`d-flex text-xxs justify-content-start align-items-center`}>
                  <Progress
                    color="primary"
                    value={progress}
                    style={{ height: '6px' }}
                    className="progress-light max-width-200px width-100 flex-grow mr-1"
                  />

                  <p style={{ lineHeight: '10px' }} className="m-0 text-xxxs text--gray7">
                    {completeByTime.format('h:mmA (MMM D)')}
                  </p>
                </div>
              )}

              <div className="text-left text--gray7 mt-1">
                <p className="m-0 single-line text-xs">
                  {isStat ? `${statTypeDescription(defaultTurnaroundTime, consultation.stat_type)} requested` : 'Requested'} at{' '}
                  {createdAtTime.format('MMM D, h:mmA')}
                </p>

                <p className="m-0 text-xs single-line">
                  Delivery{isImmediateStat ? '' : ' before'} {isImmediateStat ? 'ASAP' : completeByTime.format('MMM D, h:mmA')}
                </p>

                {completeByTime.isBefore(moment.tz()) && <p className="m-0 text-xs semibold text-danger">Please send us a message.</p>}

                {isAdmin(role) && user?.organization.enterprise.enterprise_admin_has_overflow_control && (
                  <div className="d-flex align-items-center justify-content-between text-xs gap-30px mt-1">
                    <p className={`${consultation.overflow ? 'text--success semibold' : 'text--gray7'} m-0`}>
                      {consultation.overflow ? 'Overflowing to Radimal' : 'Not overflowing'}
                    </p>

                    <Button onClick={handleUpdateOverflow} className="button--xs" color={consultation.overflow ? 'danger' : 'success'}>
                      {consultation.overflow ? 'Stop' : 'Start'}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <UncontrolledDropdown
          className={`dropdown-menu-dots d-flex justify-content-end flex-even ${
            isEditable || nextRequestConsultationType || hasMultipleCompletedConsultations ? '' : 'hidden'
          }`}
          onClick={(e: any) => e.stopPropagation()}
        >
          <DropdownToggle caret>
            <img className="icon-s ml-2 mb-1" src={dots} />
          </DropdownToggle>

          <DropdownMenu className="width-fit-content" right>
            <DropdownItem header>
              <p className="m-0 text-s text--gray8">Case actions</p>
            </DropdownItem>

            <DropdownItem text>
              {isEditable && (
                <p
                  onClick={() => navigate(`/request-consult?i=${_case.id}&e=true&c=${consultation?.id}`)}
                  className="single-line mb-2 hover-underline text-s regular pointer"
                >
                  Edit request
                </p>
              )}

              {nextRequestConsultationType && (
                <p
                  onClick={() => navigate(`/request-consult?i=${_case.id}`)}
                  className="single-line mb-2 hover-underline text-s regular pointer"
                >
                  Request {nextRequestConsultationType}
                </p>
              )}

              {hasMultipleCompletedConsultations && (
                <p
                  onClick={() =>
                    props.viewConsultationReport(
                      _case.consultations[1],
                      _case.consultations[1].s3_url!,
                      _case.patient,
                      _case.consultations[1].receiving_vet_private_notes || undefined,
                      ohifStudyUrl(_case.dicom_server_study_instance_uid, _case.dicom_source)
                    )
                  }
                  className="mb-2 hover-underline text-s regular pointer"
                >
                  View {getConsultationTypeForId(_case.consultations[1].consultation_type_id)?.consultationType}
                </p>
              )}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>

        <Modal fade={false} centered className="position-relative" isOpen={upgradeModalOpen} toggle={() => setUpgradeModalOpen(false)}>
          <ModalBody className="p-4">
            <img
              onClick={() => setUpgradeModalOpen(false)}
              className="m-2 position-absolute icon-m"
              src={closeIcon}
              style={{ top: '10px', right: '10px' }}
            />

            <h5 className="bold">Upgrade {_case.patient.display_name} to STAT</h5>

            <div className="my-4">
              {radimalCase && consultation && (
                <StatSelect
                  _case={radimalCase}
                  consultationType={
                    getConsultationTypeForId(consultation.consultation_type_id)?.consultationType || ConsultationType.Xray
                  }
                  isUpgrade
                  setStatType={setStatType}
                  species={speciesFor(radimalCase.patient.species)}
                  statType={statType}
                />
              )}
            </div>

            <Button
              size="lg"
              disabled={!statType || isQuerying[QueryName.UpgradeStat]}
              className="button-w-m mt-3"
              color="primary"
              onClick={handleUpgradeSubmit}
            >
              Upgrade
            </Button>
          </ModalBody>
        </Modal>
      </div>
    </Cell>
  )
}
