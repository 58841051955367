import gql from 'graphql-tag'

export const IRX_CONSULTATION_FIELDS = gql`
  fragment irx_consultation_fields on consultations {
    id
    case_id
    checked_denormalized
    completed_at
    consultation_type_id
    created_at
    distribution_email_ids_denormalized
    overflow
    price_amount
    receiving_vet_notes
    receiving_vet_private_notes
    sending_vet_notes
    sending_vet_private_notes
    status
    upgraded_at

    consultation_attachments {
      aws_s3_url
    }

    predictions {
      id
      display_name
      condition_category {
        id
        display_name
      }
      condition {
        id
        display_name
        category {
          display_name
        }
      }
    }

    stat_type {
      id
      hours
      display_name
    }

    addendums(order_by: { id: asc }) {
      id
      created_at
      text
      sending_vet_text
      receiving_vet {
        display_name
      }
    }

    followups(order_by: { id: asc }) {
      id
      created_at
      updated_at
      send_at
      text
      status
      response_text
    }

    receiving_vet {
      display_name
    }

    case {
      id
      created_at
      additional_data
      dicom_server_study_id
      dicom_server_study_instance_uid
      dicom_server_viewing_study_id
      dicom_server_viewing_study_instance_uid
      dicom_source

      patient {
        age
        birthdate
        breed
        dicom_server_patient_id
        display_name
        gender
        id
        neutered
        species
        weight
        cases(order_by: { created_at: desc }) {
          id
          created_at
          dicom_server_study_id
          dicom_server_study_instance_uid
          dicom_server_viewing_study_instance_uid
          dicom_source
          consultations {
            id
            s3_url
          }
          medical_images(where: { aws_s3_url: { _is_null: false } }) {
            aws_s3_url
          }
        }
      }

      medical_images(where: { aws_s3_url: { _is_null: false } }, order_by: { view: { order_index: asc } }) {
        id
        ai_predictions_denormalized
        aws_s3_url
        created_at
        dicom_server_instance_id
        is_video
        type
        medical_image_permutations {
          aws_s3_url
          ai_predictions_denormalized
          label
        }
        predictions_normalizeds(where: { environment: { _eq: "production" }, _and: { vet_id: { _is_null: true } } }) {
          id
          condition {
            display_name
          }
        }
        predictions_normalizeds_aggregate(where: { vet_id: { _is_null: false } }) {
          aggregate {
            count
          }
        }
        view {
          display_name
        }
      }

      medical_images_aggregate {
        aggregate {
          count
        }
      }

      predictions_normalizeds {
        id
        display_name
        type
        condition {
          id
          display_name
          severity
        }
      }

      dicom_server {
        id
        organization {
          id
          display_name
          primary_email
          primary_phone_number
          distribution_emails(where: { display_name: { _is_null: false } }, order_by: { display_name: asc }) {
            id
            email
            display_name
          }
          enterprise {
            id
            short_name
          }
          price_group {
            stat_type {
              hours
            }
          }
        }
      }
    }
  }
`
