import { Addons_addons } from '../hasura/graphQlQueries/types/Addons'
import { PriceGroups_price_groups_prices } from '../hasura/graphQlQueries/types/PriceGroups'
import { SpecialistPayments_specialist_payments } from '../hasura/graphQlQueries/types/SpecialistPayments'
import { pluralize } from '../lib/helpers'
import { ConsultationType, Modality } from '../lib/modalityHelpers'

const US_IMAGE_MIN_MAX = [2, 100]
const US_VIDEO_MIN_MAX = [2, 20]
const XRAY_IMAGE_MIN_MAX = [2, 30]

export const validateThresholds = (
  modality: Modality,
  prices: PriceGroups_price_groups_prices[] | SpecialistPayments_specialist_payments[],
  imageThreshold?: number,
  videoThreshold?: number
) => {
  if (modality === Modality.Ultrasound) {
    if (!imageThreshold || imageThreshold < US_IMAGE_MIN_MAX[0] || imageThreshold > US_IMAGE_MIN_MAX[1]) {
      return `Image threshold must be between ${US_IMAGE_MIN_MAX[0]} and ${US_IMAGE_MIN_MAX[1]}.`
    } else if (!videoThreshold || videoThreshold < US_VIDEO_MIN_MAX[0] || videoThreshold > US_VIDEO_MIN_MAX[1]) {
      return `Cineloop threshold must be between ${US_VIDEO_MIN_MAX[0]} and ${US_VIDEO_MIN_MAX[1]}.`
    } else if (
      prices.some(
        (p) =>
          p.addon?.additional_data['image_threshold'] === imageThreshold &&
          p.addon?.additional_data['video_threshold'] === videoThreshold
      )
    ) {
      return 'Threshold already exists.'
    }
  } else {
    if (!imageThreshold || imageThreshold < XRAY_IMAGE_MIN_MAX[0] || imageThreshold > XRAY_IMAGE_MIN_MAX[1]) {
      return `Threshold must be between ${XRAY_IMAGE_MIN_MAX[0]} and ${XRAY_IMAGE_MIN_MAX[1]}.`
    } else if (prices.some((p) => p.addon?.additional_data['threshold'] === imageThreshold)) {
      return 'Threshold already exists.'
    }
  }
}

export const findAddon = (modality: Modality, addons: Addons_addons[], newThreshold?: number, newCineloopThreshold?: number) =>
  addons.find((a) => {
    if (modality === Modality.Ultrasound) {
      return (
        a.display_name === 'Extra Images/Videos' &&
        a.additional_data['image_threshold'] === newThreshold &&
        a.additional_data['video_threshold'] === newCineloopThreshold
      )
    } else if (modality === Modality.Xray) {
      return a.display_name === 'Extra Images' && a.additional_data['threshold'] === newThreshold
    }
  })

export const thresholdDescription = (consultationType: ConsultationType, threshold: number, type?: string) => {
  if (consultationType === ConsultationType.Catscan) {
    return threshold === 1 ? '2 regions' : 'Whole body'
  } else if (type) {
    return pluralize(type, threshold)
  }
}
