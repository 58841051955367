import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import compact from 'lodash/compact'
import last from 'lodash/last'
import moment from 'moment'
import sum from 'lodash/sum'
import { Button } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import InvoiceItemsTable from '../components/invoices/table'
import Layout from '../components/layouts/Layout'
import LineItemsTable from '../components/invoices/lineItemsTable'
import MainBox from '../components/common/MainBox'
import OrganizationSelect from '../components/common/OrganizationSelect'
import PriceCalculator from '../utils/PriceCalculator'
import Statistic from '../components/invoices/statistic'
import { LineItem } from '../interfaces/LineItem'
import { MonthOption, consultationIsLate, getMonthsRange, nextMonth, parseDate, timeDiffHoursAndMinutes } from '../lib/timeHelpers'
import { OrganizationEvent } from '../lib/organizationEventTypes'
import { Organizations_organizations } from '../hasura/graphQlQueries/types/Organizations'
import { QueryName } from '../hasura/queryNames'
import { StripeInvoiceItem } from '../interfaces/StripeInvoiceItem'
import { createCustomerPortalAction, downloadInvoicesForEnterpriseAction } from '../hasura/slices/billing'
import { getAverageReturnTimesForStatTypes, skuForConsultation, statTypeDescription } from '../lib/pricingHelpers'
import { isVocn } from '../lib/enterpriseHelpers'
import { shouldDisplayTurnaroundTimes } from '../lib/userHelpers'
import { usersSelector, UsersState } from '../hasura/slices/users'

import {
  OrganizationsState,
  createDraftInvoiceAction,
  createDraftInvoiceBatchAction,
  fetchOrganizationsAction,
  insertOrganizationEventForUserAction,
  organizationsSelector,
  stripeIdForOrganizationAction,
  unsetDraftInvoiceUrlAction,
} from '../hasura/slices/organizations'

import {
  consultationsSelector,
  ConsultationsState,
  fetchOrganizationBillingAction,
  unsetCompletedConsultationsAction,
} from '../hasura/slices/consultations'

import {
  Option,
  consultationDescription,
  downloadCsv,
  formatDollarAmount,
  isAdmin,
  isOnAiFullConsultPlan,
  isSuperAdmin,
  isUser,
  niceDate,
} from '../lib/helpers'

const AI_LEGACY_PLAN_AMOUNT = 20000

export default function Invoices() {
  const dispatch = useDispatch()

  const currentMonth = moment().startOf('month').format('MM/DD/YYYY')

  const { accessToken, user, role, isRadimalEnterprise, defaultTurnaroundTime }: UsersState = useSelector(usersSelector)
  const { organizationBilling }: ConsultationsState = useSelector(consultationsSelector)
  const { organizations, stripeCustomerId, draftInvoiceUrl, isQuerying }: OrganizationsState = useSelector(organizationsSelector)

  const [columns, setColumns] = useState<any[]>([])
  const [isCreatingDraftInvoice, setIsCreatingDraftInvoice] = useState(false)
  const [month, setMonth] = useState<MonthOption>({ value: currentMonth, label: `${currentMonth} (current month)` })
  const [monthsRange, setMonthsRange] = useState<MonthOption[]>([])
  const [organization, setOrganization] = useState<Organizations_organizations | null>()

  const completedConsultations = organizationBilling?.consultations || []

  const data: LineItem[] = completedConsultations.map((c) => ({
    displayId: c.display_id,
    date: niceDate(c.completed_at),
    amount: `$${formatDollarAmount(c.price_amount)}`,
    description: consultationDescription(c),
    turnaroundTime: timeDiffHoursAndMinutes(c.upgraded_at || c.created_at, c.completed_at),
    charge_adjustment_notes: c.charge_adjustment_notes,
    sku: skuForConsultation(c),
    returnTime: statTypeDescription(defaultTurnaroundTime, c.stat_type),
    late: consultationIsLate(c),
  }))

  const displayTurnaroundTimes = shouldDisplayTurnaroundTimes(user, role)
  const displayChargeNotes = isAdmin(role)
  const displayDisplayId = isAdmin(role)
  const displaySkus = isVocn(user)

  const consultationInvoiceItems = PriceCalculator.invoiceItemsFromConsultations(completedConsultations)

  /*
    Effects
  */

  useEffect(() => {
    dispatch(insertOrganizationEventForUserAction(accessToken, OrganizationEvent.OpenedInvoices, user?.organization.id))
  }, [])

  useEffect(() => {
    if (!user || !accessToken) return

    if (isAdmin(role) && !organizations.length) {
      dispatch(fetchOrganizationsAction(accessToken, user.organization.enterprise.id))
    } else if (isUser(role)) {
      setOrganization(user.organization)
    }

    setColumns(
      [
        displayDisplayId && {
          Header: 'ID',
          accessor: (d: LineItem) => d.displayId,
        },
        {
          Header: 'Date',
          accessor: (d: LineItem) => parseDate(d.date),
        },
        {
          Header: 'Description',
          accessor: 'description',
        },
        {
          Header: 'Return time',
          accessor: (d: LineItem) => `${d.returnTime}`,
        },
        displayTurnaroundTimes && {
          Header: 'Turnaround time',
          accessor: (d: LineItem) => d.turnaroundTime,
        },
        displayChargeNotes && {
          Header: 'Charge notes',
          accessor: 'charge_adjustment_notes',
        },
        displaySkus && {
          Header: 'SKU',
          accessor: 'sku',
        },
        {
          Header: 'Amount (USD)',
          accessor: 'amount',
        },
      ].filter((o) => o)
    )

    return () => {
      dispatch(unsetCompletedConsultationsAction())
    }
  }, [user, accessToken, role])

  useEffect(() => {
    if (!organization || !month?.value) return

    dispatch(unsetDraftInvoiceUrlAction())
    dispatch(fetchOrganizationBillingAction(accessToken, month.value, nextMonth(month), organization.id))
  }, [organization, month])

  useEffect(() => {
    if (!organization || !accessToken) return

    const start = new Date(organization.created_at).toLocaleDateString('en-US')
    const end = new Date().toLocaleDateString('en-US')
    const monthsRange = getMonthsRange(start, end)

    setMonthsRange(monthsRange)
    if (monthsRange.length) setMonth(last(monthsRange)!)
    if (isAdmin(role)) dispatch(stripeIdForOrganizationAction(accessToken, organization.id))
  }, [accessToken, organization])

  /*
    Methods
  */

  const createDraftInvoice = async () => {
    setIsCreatingDraftInvoice(true)
    await dispatch(createDraftInvoiceAction(stripeCustomerId!, invoiceItems))
    setIsCreatingDraftInvoice(false)
  }

  const createDraftInvoiceBatch = () => {
    if (!user) return

    dispatch(
      createDraftInvoiceBatchAction(
        accessToken,
        user.organization.enterprise.id,
        moment().subtract(1, 'month').startOf('month').format('MM/DD/YYYY'),
        moment().startOf('month').format('MM/DD/YYYY')
      )
    )
  }

  const handleClickedBilling = () => {
    const id = user?.related_accounts?.stripe_profile?.id
    if (id) dispatch(createCustomerPortalAction(id))
  }

  const downloadAllInvoices = () =>
    dispatch(downloadInvoicesForEnterpriseAction(accessToken, month.value, nextMonth(month), user!.organization.enterprise.id))

  const handleDownloadAsCsv = () => {
    const filename = `Radimal-invoice-${month?.value}.csv`
    const headers = [`Date,Description,${displayTurnaroundTimes ? 'Turnaround Time,' : ''}${displaySkus ? 'SKU,' : ''}Amount (USD)`]
    const rows = headers.concat(
      ...data
        .map((c: LineItem) =>
          compact([
            c.date,
            c.description,
            displayTurnaroundTimes ? c.turnaroundTime : undefined,
            displaySkus ? c.sku : undefined,
            c.amount,
          ]).join(',')
        )
        .concat(`TOTAL,,${displayTurnaroundTimes ? ',' : ''}${displaySkus ? ',' : ''}$${invoiceTotal()}`)
    )

    downloadCsv(filename, rows)

    dispatch(insertOrganizationEventForUserAction(accessToken, OrganizationEvent.DownloadedInvoices, user?.organization.id))
  }

  const handleSelectOrganization = (option: Option | null) => setOrganization(organizations.find((o) => o.id === option?.value))

  const casesStored = organizationBilling?.cases_stored.aggregate?.count || 0
  const aiAssessments = organizationBilling?.ai_assessments.aggregate?.count || 0

  const isOnAiLegacyPlan = isOnAiFullConsultPlan(user)

  const invoiceTotal = () =>
    formatDollarAmount(
      sum(completedConsultations.map((c) => c.price_amount)) + (isOnAiLegacyPlan ? AI_LEGACY_PLAN_AMOUNT / 100 : 0)
    ).toLocaleString()

  const invoiceItems: StripeInvoiceItem[] = organizationBilling
    ? [
        {
          description: 'Case stored',
          quantity: casesStored,
          unit_amount: 0,
        },
        {
          description: 'AI assessment',
          quantity: aiAssessments,
          unit_amount: 0,
          unit_amount_text: isOnAiLegacyPlan ? 'AI Legacy Plan' : undefined,
          amount: isOnAiLegacyPlan ? AI_LEGACY_PLAN_AMOUNT : undefined,
        },
      ].concat(...consultationInvoiceItems)
    : []

  const returnTimeMet = Math.round(
    (100 * completedConsultations.filter((c) => !consultationIsLate(c)).length) / completedConsultations.length
  )

  const averageReturnTimesForStatTypes = getAverageReturnTimesForStatTypes(completedConsultations)

  return (
    <Layout>
      <MainBox defaultPadding>
        <div className="d-flex align-items-start justify-content-between mb-2">
          <div className="mb-4">
            <h4 className="bold">Invoices</h4>

            <div className="d-flex flex-column gap-10px width-350px">
              {isAdmin(role) && (
                <OrganizationSelect
                  organization={organization}
                  organizations={organizations}
                  handleSelectOrganization={handleSelectOrganization}
                />
              )}

              <Select
                placeholder="Select month..."
                classNamePrefix="react-select"
                options={monthsRange}
                value={month}
                // @ts-ignore
                onChange={(o: MonthOption) => setMonth(o)}
              />
            </div>
          </div>

          <div className="d-flex flex-column">
            {isUser(role) && (
              <Button color="primary" disabled={!data.length} onClick={handleDownloadAsCsv}>
                Download Consultations
              </Button>
            )}

            {isAdmin(role) && (
              <Button color="primary" onClick={downloadAllInvoices}>
                Download all invoices
              </Button>
            )}

            {isSuperAdmin(role) && (
              <Button className="mt-2" outline disabled={isQuerying[QueryName.DraftInvoiceBatch]} onClick={createDraftInvoiceBatch}>
                Create invoices for last month
              </Button>
            )}
          </div>
        </div>

        <div
          style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', gap: '20px' }}
          className="mb-4 w-100"
        >
          <Statistic title="Total Cases Stored" content={casesStored} />
          <Statistic title="Total AI Initial Assessments" content={aiAssessments} />
          <Statistic
            title="Total Full Consults"
            content={`${completedConsultations.length} (${completedConsultations.filter((c) => c.stat_type).length} STAT)`}
          />
          {averageReturnTimesForStatTypes.map((s) => (
            // @ts-ignore
            <Statistic title={`${s.description} Average Turnaround`} content={s.turnaroundTime} key={s.description} />
          ))}
          {completedConsultations.length > 0 && (
            <Statistic title="Return Time Met" content={`${returnTimeMet}%`} highlight={returnTimeMet === 100} />
          )}
        </div>

        <div className="max-width-1300px">
          {data.length > 0 && <InvoiceItemsTable data={invoiceItems} total={invoiceTotal()} />}

          <LineItemsTable data={data} columns={columns} handleDownloadAsCsv={handleDownloadAsCsv} />

          {isSuperAdmin(role) && (
            <div className="mt-4 align-items-end d-flex">
              <Button
                className="d-block"
                outline
                disabled={!data.length || !stripeCustomerId || isCreatingDraftInvoice}
                onClick={createDraftInvoice}
              >
                Create invoice
              </Button>

              <a
                style={{ opacity: draftInvoiceUrl ? 1 : 0 }}
                target="_blank"
                href={draftInvoiceUrl}
                className="text-link transition-m text-m ml-2"
              >
                View draft invoice
              </a>
            </div>
          )}

          {isUser(role) && isRadimalEnterprise && (
            <Button outline className="mt-4 d-block" onClick={handleClickedBilling}>
              Billing Settings
            </Button>
          )}
        </div>
      </MainBox>
    </Layout>
  )
}
